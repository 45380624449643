.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
html{
  font-family: Roboto,Arial,sans-serif;
}

.bg-header-top {
  background: url('https://p2pb2b.com/static/img/tokenSale/top-bg.png'), #0b0916 !important;
  /* background-size: cover; */
  padding-top: 100px;
  padding-bottom: 90px;
  text-align: center;
}

.salse-list {
  width: 340px;
  margin: 0 10px 25px;
  position: relative;
  box-shadow: 0 4px 45px rgb(0 0 0 / 8%);
  transition: box-shadow .5s;
  background-color: var(--c-card-bg, #fff);
  font-weight: bold;
}